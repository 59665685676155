import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import parse from 'html-react-parser'

export const Parallax = ({ backgroundImage, heading, description }) => (
  <Container
    style={{
      backgroundAttachment: 'fixed',
      backgroundImage: `url(${backgroundImage})`,
      backgroundPosition: 'center center',
    }}
    fluid
    className="parallax-outer"
  >
    <Row className="p-lg-5 p-md-2">
      <Col xs={12} lg={5} className="p-lg-4 p-md-2">
        <div className="bg-white p-3 mx-auto my-5 w-75 parallax-content">
          <h2 className="p-3 parallax-module-heading">{parse(heading)}</h2>
          {description && (
            <div className="p-3 module-description">{parse(description)}</div>
          )}
        </div>
      </Col>
    </Row>
  </Container>
)

export const mapParallaxProps = props => {
  return {
    backgroundImage: props.backgroundImage.file.url,
    heading: props.heading,
    description: props?.description?.description,
  }
}
